import styled, { css } from 'styled-components';
import { rem } from 'polished';
import { breakpoint } from '../theme';

export const SectionTitle = styled.h2`
  display: block;
  width: 100%;
  margin: 0 0 0.45em 0;
  font-size: ${rem(26)};
  font-weight: ${({ theme }) => theme.typography.weights.heading.normal};
  font-family: ${({ theme }) => theme.typography.fonts.heading};
  color: ${(props) => props.theme.palette.colors.primary};

  ${breakpoint('mediumlarge')} {
    font-size: ${rem(32)};
  }

  ${breakpoint('large')} {
    font-size: ${rem(38)};
  }

  ${({ big }) =>
    big &&
    css`
      font-size: ${rem(30)};

      ${breakpoint('mediumlarge')} {
        font-size: ${rem(36)};
      }

      ${breakpoint('large')} {
        font-size: ${rem(42)};
      }
    `}

  ${({ centered }) =>
    centered &&
    css`
      text-align: center;
    `}
`;

export const StyledContent = styled.div`
  h4,
  h5,
  h6 {
    margin: 0 0 ${rem(16)} 0;
    font-size: ${rem(18)};
    text-transform: uppercase;
    letter-spacing: 0.065em;

    ${breakpoint('mediumlarge')} {
      font-size: ${rem(18)};
    }

    ${breakpoint('large')} {
      font-size: ${rem(21)};
    }
  }

  p {
    color: ${(props) => props.theme.palette.colors.greyDark};
    font-size: ${rem(17)};

    ${breakpoint('mediumlarge')} {
      font-size: ${rem(17)};
    }
  }
`;
