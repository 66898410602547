import React from 'react';
import PropTypes from 'prop-types';
import { rem } from 'polished';
import { StaticQuery, graphql } from 'gatsby';
import styled, { css } from 'styled-components';
import { useLocation } from '@reach/router';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFacebook,
  faInstagram,
  faTwitter,
  faLinkedin,
  faReddit,
  faPinterest,
  faWhatsapp,
} from '@fortawesome/free-brands-svg-icons';
import {
  FacebookShareButton,
  PinterestShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  RedditShareButton,
} from 'react-share';
import theme from '../../theme';

const Root = styled.div`
  display: block;
  width: 100%;
`;

const Item = styled.div`
  display: inline-block;
  margin: 0 0.4rem 0.8rem 0;
`;

const Button = styled.div`
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  border: 1px solid ${(props) => props.theme.global.border.color};
  color: ${(props) => props.theme.palette.text.primary};
  border-radius: 999px;
  padding: 0.42em 0.8em;
  font-size: ${rem(16)};

  ${() => {
    let styles = '';
    Object.keys(theme.palette.social).forEach((key) => {
      styles += `
      &.${key} {
        border-color: ${theme.palette.social[key]};
        color: ${theme.palette.social[key]};
      }\n
    `;
    });

    return css`
      ${styles}
    `;
  }};

  ${({ variant }) =>
    variant === 'label' &&
    css`
      /* text-align: left; */
      width: 100%;
    `};
`;

const Label = styled.span`
  display: inline-block;
  padding-left: 0.65em;
  line-height: 1;
  text-align: left;
  font-size: ${rem(15)};
  vertical-align: middle;
`;

const Icon = styled.span`
  display: inline-block;
  vertical-align: middle;
  min-width: 1em;
`;

const query = graphql`
  query {
    site {
      siteMetadata {
        twitter
        siteUrl
      }
    }
  }
`;

function SocialLinks({ path, title, label, image, description }) {
  const { pathname: routerPathname } = useLocation();

  return (
    <StaticQuery
      query={`${query}`} // String hack needed to fix https://github.com/birkir/gatsby-source-prismic-graphql/issues/70
    >
      {({
        site: {
          siteMetadata: { siteUrl, twitter: twitterHandle },
        },
      }) => {
        const socialItems = [
          {
            id: 'facebook',
            name: 'Facebook',
            tag: FacebookShareButton,
            icon: <FontAwesomeIcon icon={faFacebook} />,
          },
          {
            id: 'twitter',
            name: 'Twitter',
            tag: TwitterShareButton,
            icon: <FontAwesomeIcon icon={faTwitter} />,
            via: twitterHandle.split('@').join(''),
            // hashtags={tags} // Also for twitter only
          },
          {
            id: 'instagram',
            name: 'Instagram',
            tag: FacebookShareButton,
            icon: <FontAwesomeIcon icon={faInstagram} />,
          },
          {
            id: 'linkedin',
            name: 'Linkedin',
            tag: LinkedinShareButton,
            icon: <FontAwesomeIcon icon={faLinkedin} />,
          },
          {
            id: 'reddit',
            name: 'Reddit',
            tag: RedditShareButton,
            icon: <FontAwesomeIcon icon={faReddit} />,
          },
          {
            id: 'pinterest',
            name: 'Pinterest',
            tag: PinterestShareButton,
            icon: <FontAwesomeIcon icon={faPinterest} />,
            media: image,
            description,
          },
          {
            id: 'whatsapp',
            name: 'WhatsApp',
            tag: WhatsappShareButton,
            icon: <FontAwesomeIcon icon={faWhatsapp} />,
          },
        ];

        return (
          <Root>
            {socialItems.map((item) => {
              const { id, tag: Tag, ...itemOther } = item;
              return (
                <Item
                  as={Tag}
                  key={id}
                  url={`${siteUrl}${path || routerPathname}`}
                  title={title}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...itemOther}
                >
                  <Button className={item.id}>
                    <Icon>{item.icon}</Icon>
                    {label && <Label>{item.name}</Label>}
                  </Button>
                </Item>
              );
            })}
          </Root>
        );
      }}
    </StaticQuery>
  );
}

SocialLinks.propTypes = {
  title: PropTypes.string.isRequired,
  path: PropTypes.string,
  label: PropTypes.bool,
  image: PropTypes.string,
  description: PropTypes.string,
};

SocialLinks.defaultProps = {
  path: undefined,
  label: true,
  image: undefined,
  description: undefined,
};

export default SocialLinks;
