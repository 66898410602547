import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { rem } from 'polished';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import { getImage, getSrc } from 'gatsby-plugin-image';

import useLangRedirect from '../hooks/useLangRedirect';

import SEO from '../components/SEO';
import Layout from '../components/Layout';
import Row from '../components/Row';
import Col from '../components/Col';
import Hero from '../components/Hero';
import Image from '../components/Image';
import SocialShare from '../components/SocialShare';
import Avatar from '../components/Avatar';
import BorderTitle from '../components/BorderTitle';
import Link from '../components/Link';

import { SectionTitle } from '../utils/GlobalFormatting';
import { breakpoint } from '../theme';

import { SiteInformation } from '../utils/fragments';

const Container = styled.div`
  max-width: ${(props) => props.theme.grid.maxWidthText};
  margin: ${rem(0)} auto 0;
  padding-top: ${rem(50)};
  padding-bottom: ${rem(100)};
`;

const FeaturedImage = styled(Image)`
  display: block;
  width: 100%;
`;

const PostTitle = styled.h1`
  margin: 0;
  font-size: ${rem(36)};
  font-weight: ${(props) => props.theme.typography.weights.heading.bold};

  ${breakpoint('medium')} {
    font-size: ${rem(38)};
  }

  ${breakpoint('mediumlarge')} {
    font-size: ${rem(42)};
  }

  ${breakpoint('large')} {
    font-size: ${rem(46)};
  }

  strong,
  b {
    color: ${(props) => props.theme.palette.colors.primary};
  }
`;

// const PreHeading = styled.h4`
//   display: block;
//   width: 100%;
//   font-size: ${rem(16)};
//   color: ${(props) => props.theme.palette.colors.primary};
//   font-weight: ${(props) => props.theme.typography.weights.heading.normal};
//   font-family: ${(props) => props.theme.typography.fonts.alt};
//   text-transform: uppercase;
//   letter-spacing: 0.07em;

//   ${breakpoint('large')} {
//     font-size: ${rem(17)};
//   }
// `;

const PostHeader = styled.header`
  display: block;
  width: 100%;
  padding-bottom: ${rem(25)};
`;

const PostMeta = styled.div`
  width: 100%;
  margin-top: ${rem(10)};
  color: ${(props) => props.theme.palette.colors.fadedText};
  font-size: ${rem(13)};

  ${breakpoint('large')} {
    font-size: ${rem(14.5)};
  }
`;

const PostMetaAuthor = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`;

const PostMetaAuthorName = styled.div`
  padding-left: ${rem(10)};
  padding-right: ${rem(10)};
`;

const MetaItem = styled.span`
  display: inline-block;
  margin-right: ${rem(6)};
`;

const PostShare = styled.div`
  display: block;
  width: 100%;
  margin-top: ${rem(42)};
`;

const Author = styled.div`
  display: flex;
  width: 100%;
  padding: 1.2rem;
  /* margin-bottom: ${rem(60)}; */
  border: 1px solid ${(props) => props.theme.global.border.color};
  font-size: ${rem(14.5)};
`;

const AuthorContent = styled.div`
  padding-left: ${rem(20)};
`;

const AuthorImage = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: ${rem(120)};
`;

const PostContent = styled.div`
  font-size: ${rem(16.5)};

  ${breakpoint('large')} {
    font-size: ${rem(18)};
  }

  & .wp-block-image {
    margin-top: ${rem(32)};
    margin-bottom: ${rem(22)};
  }

  & figcaption {
    color: ${(props) => props.theme.palette.colors.fadedText};
    font-size: ${rem(14)};
    font-weight: ${(props) => props.theme.typography.weights.primary.normal};
    font-family: ${(props) => props.theme.typography.fonts.primary};
  }
`;

function Post({ data }) {
  const { t } = useTranslation();
  const { page } = data;

  useLangRedirect(page);

  if (!page) {
    return null;
  }

  const { featuredImage, lang, seo } = page;
  const featuredMedia = featuredImage?.node;

  const withRelated = false;
  const withHero = false;
  const withAuthor = false;
  const withShare = false;

  const {
    name: authorName,
    description: authorDescription,
    avatar,
  } = page.author.node;

  const shareConfig = {
    path: page.uri,
    title: page.title,
  };

  const itemImage = getImage(featuredMedia?.localFile);
  const itemImageSrc = getSrc(featuredMedia?.localFile);

  return (
    <Layout>
      <SEO
        title={seo.title}
        description={seo.metaDesc || seo.opengraphDescription}
        image={itemImageSrc}
        article
        page={page}
        lang={lang}
      />

      {withHero && (
        <Hero
          heading={page.title}
          image={page.featured_media?.localFile}
          pageTitle
          preHeading={page.categories.nodes.map((cat) => (
            <span>{cat.name}</span>
          ))}
          // actions="Actions"
          tint
          big
        >
          {DateTime.fromISO(page.date).toFormat('dd LLL, yyyy')}
        </Hero>
      )}

      {itemImage && (
        <FeaturedImage
          image={itemImage}
          fallback={{
            url: itemImageSrc,
            alt: featuredMedia.altText,
          }}
          alt={featuredMedia.altText}
        />
      )}

      <Container>
        <PostHeader>
          <Row>
            <Col>
              <PostTitle>{page.title}</PostTitle>
              <PostMeta>
                <MetaItem>
                  <PostMetaAuthor>
                    <Avatar
                      width={rem(32)}
                      image={{
                        fallback: {
                          url: avatar.url,
                          alt: authorName,
                        },
                        alt: authorName,
                      }}
                    />
                    <PostMetaAuthorName>{authorName}</PostMetaAuthorName>
                  </PostMetaAuthor>
                </MetaItem>
                <MetaItem>
                  {DateTime.fromISO(page.date).toFormat('dd LLL, yyyy')}
                </MetaItem>
                <MetaItem>{' · '}</MetaItem>
                <MetaItem>
                  {page.categories.nodes.map((cat, index) => {
                    return (
                      <span key={cat.id}>
                        {index > 0 && ', '}
                        <Link to={cat.uri}>{cat.name}</Link>
                      </span>
                    );
                  })}
                </MetaItem>
              </PostMeta>
            </Col>
          </Row>
        </PostHeader>

        <Row>
          <Col>
            <PostContent
              dangerouslySetInnerHTML={{ __html: page.content }} // eslint-disable-line react/no-danger
            />
          </Col>
        </Row>

        {withShare && (
          <Row>
            <Col>
              <PostShare as="aside">
                <h6>{t('shareThis')}</h6>
                <SocialShare
                  url={shareConfig.url}
                  title={page.title}
                  image={itemImageSrc}
                  description={page.content}
                />
              </PostShare>
            </Col>
          </Row>
        )}

        {withAuthor && (
          <Row style={{ marginTop: rem(60) }}>
            <Col>
              <h4>{t('theAuthor')}</h4>
              <Author>
                <AuthorImage>
                  <Avatar
                    width={rem(50)}
                    image={{
                      fallback: {
                        url: avatar.url,
                        alt: authorName,
                      },
                      alt: authorName,
                    }}
                  />
                </AuthorImage>
                <AuthorContent>
                  <h5>{authorName}</h5>
                  <p>{authorDescription}</p>
                </AuthorContent>
              </Author>
            </Col>
          </Row>
        )}
        {withRelated && (
          <Row>
            <Col>
              <BorderTitle>{t('moreNews')}</BorderTitle>
              <SectionTitle>{t('moreNews')}</SectionTitle>
            </Col>
          </Row>
        )}
      </Container>
    </Layout>
  );
}

Post.propTypes = {
  data: PropTypes.objectOf(PropTypes.object).isRequired,
};

Post.fragments = [SiteInformation];

export const query = graphql`
  query ($id: String!) {
    page: wpPost(id: { eq: $id }) {
      id
      featuredImage {
        node {
          localFile {
            childImageSharp {
              gatsbyImageData(
                quality: 75
                transformOptions: { cropFocus: ENTROPY }
                layout: FULL_WIDTH
              )
            }
          }
          sourceUrl
          caption
          altText
          title
          guid
          id
          description
        }
      }
      content
      title
      slug
      uri
      seo {
        breadcrumbs {
          text
          url
        }
        canonical
        cornerstone
        focuskw
        metaDesc
        metaKeywords
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphAuthor
        opengraphDescription
        opengraphImage {
          altText
          sourceUrl
          srcSet
        }
        opengraphModifiedTime
        opengraphPublishedTime
        opengraphPublisher
        opengraphSiteName
        opengraphTitle
        opengraphType
        opengraphUrl
        schema {
          articleType
          pageType
        }
        title
        twitterDescription
        twitterImage {
          altText
          sourceUrl
          srcSet
        }
        twitterTitle
      }
      author {
        node {
          name
          firstName
          lastName
          description
          avatar {
            url
          }
          seo {
            metaDesc
            metaRobotsNofollow
            metaRobotsNoindex
            title
            social {
              youTube
              wikipedia
              twitter
              soundCloud
              pinterest
              mySpace
              linkedIn
              instagram
              facebook
            }
          }
        }
      }
      categories {
        nodes {
          id
          name
          uri
        }
      }
      date
      excerpt
      modified
      tags {
        nodes {
          name
        }
      }
      locale {
        id
        locale
      }
      lang
      translated {
        id
        uri
        title
        lang
        locale {
          id
          locale
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
    site {
      ...SiteInformation
    }
  }
`;

export default Post;
