/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';

import Image from '../Image';

const Root = styled.div`
  display: block;
  width: ${({ width }) => width};
  overflow: hidden;
  border-radius: 999px;
`;

function Avatar({ image, width }) {
  return (
    <Root width={width}>
      <Image {...image} style={{ width: '100%' }} />
    </Root>
  );
}

Avatar.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object]).isRequired,
  width: PropTypes.string,
};

Avatar.defaultProps = {
  width: '100%',
};

export default Avatar;
